@import "tailwindcss/base";
@import "tailwindcss/base.css";

@import "tailwindcss/components";
@import "tailwindcss/components.css";

@import "tailwindcss/utilities";
@import "tailwindcss/utilities.css";

@import 'material-icons/iconfont/material-icons.css';

@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;

body {
    min-height: 100vh;
}

.font {
    @apply text-5xl font-sans font-black;
}

html {
    font-family: Google Sans, -apple-system, BlinkMacSystemFont, sans-serif;
}

.subtitle {
    margin-top: 25px;
}

.subtitle-font {
    @apply text-4xl font-sans font-light;
}

/*.scrollbar-hide {*/
/*    --scrollbar-width: 0;*/
/*}*/

.systempiloten-buttonScale {
    @apply transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110;
}

.systempiloten-textTransition {
    @apply transition duration-500 ease-in-out hover:text-systempiloten-yellow;
}

.backgroundTint {
    background-color: rgba(0, 0, 0, 0.85);
    background-blend-mode: multiply;
}

.force-white.prose > * {
    color: white !important;
}

.font-calc-cards {
    font-size: calc(2vh - 10%);
}

.w-inherit {
    width: inherit;
}

.h-inherit {
    height: inherit;
}

img {
    display: none;
}


img[src] {
    display: block;
}

.video-container {
    padding-top: 45px;
}
.break-spaces {
    white-space: break-spaces;
}

@-webkit-keyframes slideUp {
    0% {
        transform: translateY(100%);
        overflow: hidden;
    }
    100% {
        transform: translateY(0%);
    }
}

@keyframes slideUp {
    0% {
        transform: translateY(100%);
        overflow: hidden;
    }
    100% {
        transform: translateY(0%);
    }
}

.animate-slideUp {


    -webkit-animation: slideUp 1s ease-in-out;
    /*-webkit-animation-delay: 2s;*/
    animation: slideUp 1s ease-in-out;
    /*animation-delay: 2s;*/
}
